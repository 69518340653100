import nlMessages from 'constants/translations/nl.json';
import enMessages from 'constants/translations/en.json';
import deMessages from 'constants/translations/de.json';
import plMessages from 'constants/translations/pl.json';
import { LOCALES } from 'constants/locales';

export const getMessagesForLocale = (locale: string): Record<string, string> => {
	switch (locale) {
		case LOCALES.NL:
			return nlMessages;
		case LOCALES.EN:
			return enMessages;
		case LOCALES.DE:
			return deMessages;
		case LOCALES.PL:
			return plMessages;
		default:
			return nlMessages;
	}
};
