/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckboxItem } from 'components/core/checkbox-group';
import { RadioButtonItem } from 'components/core/radio-button-group';
import { SelectOption } from 'components/core/select';
import { ITabItem } from 'components/tab-filter/tab-item/tab-item';
import xorBy from 'lodash/xorBy';
import { SubsidyFieldsFragment } from '../../api/graphql-types';

export interface SubsidyAlgoliaFragment extends Omit<SubsidyFieldsFragment, 'modules'> {}

export interface IActiveFilter {
	key: keyof ISubsidyState;
	id?: string;
	label?: string;
}

export interface ISubsidyState {
	isLoading: boolean;
	subsidy: SubsidyAlgoliaFragment[];
	subsidyCount: number;
	currentPage: number;
	searchValue: string;
	kind?: RadioButtonItem;
	targetGroup?: RadioButtonItem;
	scale?: RadioButtonItem;
	sort: SelectOption;
	status?: ITabItem;
	selectedThemes: CheckboxItem[];
	amountOfActiveFilters: number;
	activeFilters: IActiveFilter[];
}

export const initialState: ISubsidyState = {
	subsidy: [],
	subsidyCount: 0,
	currentPage: 1,
	searchValue: '',
	selectedThemes: [],
	sort: {
		id: 'title:asc',
		label: 'A tot Z'
	},
	status: {
		id: 'open',
		label: 'Open'
	},
	amountOfActiveFilters: 0,
	activeFilters: [],
	isLoading: false
};

export const slice = createSlice({
	name: 'subsidy',
	initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setSubsidies: (state, action: PayloadAction<any>) => {
			state.subsidy = action.payload;
		},
		setSubsidyCount: (state, action: PayloadAction<number>) => {
			state.subsidyCount = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		setSearchValue: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload;
			state.currentPage = 1;
		},
		setSortValue: (state, action: PayloadAction<SelectOption>) => {
			state.sort = action.payload;
			state.currentPage = 1;
		},
		setStatusValue: (state, action: PayloadAction<ITabItem | undefined>) => {
			state.status = action.payload;
			state.currentPage = 1;
		},
		setTargetGroupValue: (state, action: PayloadAction<RadioButtonItem | undefined>) => {
			state.targetGroup = action.payload;
			state.currentPage = 1;
		},
		setKindValue: (state, action: PayloadAction<RadioButtonItem | undefined>) => {
			state.kind = action.payload;
			state.currentPage = 1;
		},
		setScaleValue: (state, action: PayloadAction<RadioButtonItem | undefined>) => {
			state.scale = action.payload;
			state.currentPage = 1;
		},
		toggleCheckboxItem: (state, action: PayloadAction<CheckboxItem[]>) => {
			// Toggle array item with id
			const newThemeIds = xorBy(state.selectedThemes, action.payload, (theme) => theme.id);
			state.selectedThemes = newThemeIds;
			state.currentPage = 1;
		},
		resetFilters: (state) => {
			state.currentPage = initialState.currentPage;
			state.kind = initialState.kind;
			state.status = initialState.status;
			state.sort = initialState.sort;
			state.targetGroup = initialState.targetGroup;
			state.searchValue = initialState.searchValue;
			state.selectedThemes = initialState.selectedThemes;
			state.scale = initialState.scale;
		},
		resetFilter: (state, action: PayloadAction<IActiveFilter>) => {
			if (action.payload.key === 'selectedThemes') {
				state.selectedThemes = state.selectedThemes.filter((theme) => theme.id !== action.payload.id);
			} else {
				// TODO: fix typings?
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//  @ts-ignore
				state[action.payload.key] = initialState[action.payload.key];
			}
		},
		setAmountOfActiveFilters: (state) => {
			const filters = ['kind', 'targetGroup', 'scale', 'searchValue', 'sort'];
			let activeFilters: IActiveFilter[] = state.activeFilters || [];

			filters.map((filter) => {
				const filterKey = filter as keyof ISubsidyState;
				const filterIndex = activeFilters.findIndex((item) => item.key === filter);
				const currentFilter = state[filterKey] as SelectOption;
				const initialFilter = initialState[filterKey] as SelectOption;

				if (filterIndex !== -1 && !currentFilter) {
					activeFilters.splice(filterIndex, 1);
				}

				if (currentFilter && currentFilter.id !== initialFilter?.id) {
					if (filterIndex === -1) {
						activeFilters = [
							...activeFilters,
							{
								id: String(currentFilter.id),
								label: currentFilter.label,
								key: filterKey
							}
						];
					} else {
						activeFilters[filterIndex].id = String(currentFilter.id);
						activeFilters[filterIndex].label = currentFilter.label;
					}
				}
			});

			const activeThemeFilters = state.selectedThemes
				.map(
					(themeFilter) =>
						({
							id: themeFilter.id,
							label: themeFilter?.label,
							key: 'selectedThemes'
						} as IActiveFilter)
				)
				.filter((item) => activeFilters.findIndex((activeItem) => activeItem.id === item.id) === -1);

			activeFilters = [
				...activeFilters.filter(
					(item) =>
						item.key !== 'selectedThemes' || state.selectedThemes.findIndex((theme) => theme.id === item.id) !== -1
				),
				...activeThemeFilters
			];
			state.activeFilters = activeFilters;
			state.amountOfActiveFilters = activeFilters.length;
		},
		resetStore: (state) => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			state = initialState;
		}
	}
});

export const {
	setSubsidies,
	resetFilters,
	setCurrentPage,
	setSubsidyCount,
	setSearchValue,
	setSortValue,
	setStatusValue,
	setTargetGroupValue,
	setKindValue,
	setScaleValue,
	setAmountOfActiveFilters,
	toggleCheckboxItem,
	setIsLoading,
	resetStore,
	resetFilter
} = slice.actions;

export default slice.reducer;
