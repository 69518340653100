/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IScale {
	name: string;
	locale: string;
	id: string;
}

export interface IScalesState {
	scales: IScale[];
	totalScales?: number;
}

export const initialState: IScalesState = {
	scales: [],
	totalScales: undefined
};

export const slice = createSlice({
	name: 'scales',
	initialState,
	reducers: {
		setScales: (state, action: PayloadAction<IScale[]>) => {
			state.scales = action.payload;
		},
		setTotalScales: (state, action: PayloadAction<number>) => {
			state.totalScales = action.payload;
		}
	}
});

export const { setScales, setTotalScales } = slice.actions;

export default slice.reducer;
