/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventFieldsFragment } from '../../api/graphql-types';

export interface EventAlgoliaFragment extends Omit<EventFieldsFragment, 'modules'> {}

export interface IEventsState {
	events: EventFieldsFragment[];
	eventsCount: number;
	currentPage: number;
}

export const initialState: IEventsState = {
	events: [],
	eventsCount: 0,
	currentPage: 1
};

export const slice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		setEvents: (state, action: PayloadAction<any>) => {
			state.events = action.payload;
		},
		setTotalEvents: (state, action: PayloadAction<number>) => {
			state.eventsCount = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		resetStore: (state) => {
			state.events = [];
			state.currentPage = 1;
			state.eventsCount = 0;
		}
	}
});

export const { setEvents, resetStore, setCurrentPage, setTotalEvents } = slice.actions;

export default slice.reducer;
