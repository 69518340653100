import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client';
import introspectionResult from './possibleTypes.json';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache({ possibleTypes: introspectionResult.possibleTypes });

export const httpLink = new HttpLink({
	uri: process.env.NEXT_PUBLIC_GRAPHQL_URL || 'http://localhost:1337/graphql'
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(({ message, locations, path }) => {
			console.error(JSON.stringify({ message, path }, null, 2));
		});
	// @ts-ignore
	if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError?.result?.errors)}`);
});

export const setPasswordProtectionToken = (token: string): ApolloLink =>
	setContext((_, { headers }) => ({
		headers: {
			...headers,
			authorization: token
		}
	}));

export const setPreviewSecret = (secret: string): ApolloLink => {
	return setContext((_, { headers }) => ({
		headers: {
			...headers,
			'x-strapi-preview-secret': secret
		}
	}));
};

const client = new ApolloClient({
	link: from([errorLink, httpLink]),
	cache,
	defaultOptions: {
		query: {
			fetchPolicy: 'network-only'
		}
	}
});

export default client;
