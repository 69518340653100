/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectFieldsFragment } from '../../api/graphql-types';

export interface ProjectsAlgoliaFragment extends Omit<ProjectFieldsFragment, 'modules'> {}

export interface IProjectsState {
	projects: ProjectsAlgoliaFragment[];
	projectsCount: number;
	currentPage: number;
}

export const initialState: IProjectsState = {
	projects: [],
	projectsCount: 0,
	currentPage: 1
};

export const slice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		setProjects: (state, action: PayloadAction<any>) => {
			state.projects = action.payload;
		},
		setTotalProjects: (state, action: PayloadAction<number>) => {
			state.projectsCount = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		resetStore: (state) => {
			state.projects = [];
			state.currentPage = 1;
			state.projectsCount = 0;
		}
	}
});

export const { setProjects, resetStore, setCurrentPage, setTotalProjects } = slice.actions;

export default slice.reducer;
