import React from 'react';
import Script from 'next/script';
import NextAdapterPages from 'next-query-params';
import { Provider as ReduxProvider } from 'react-redux';
import { SessionProvider } from 'next-auth/react';
import { QueryParamProvider } from 'use-query-params';
import { ToastContainer } from 'react-toastify';

import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/nl';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/pl';
import { IntlProvider } from 'react-intl';

import type { AppProps } from 'next/app';
import configureAppStore from 'redux/store';
import { getMessagesForLocale } from 'utils/intl';

import { ApolloProvider } from '@apollo/client';
import client from 'api/apollo-client';

import 'styles/main.scss';
import 'styles/date-picker-styling.scss';
import 'react-toastify/dist/ReactToastify.css';

import globalStylesOost from 'styles/global-oost';
import globalStyles from 'styles/global-gelderland';

const GelderlandClientApp = ({
	Component,
	pageProps: { session, ...pageProps },
	router
}: AppProps): React.JSX.Element => {
	let locale;

	// Dirty fix for quick polish page
	if (pageProps?.projectBySlug?.fullPath === 'projecten/life-co-2-sand-polski') {
		locale = 'pl';
	} else {
		locale = router.locale as string;
	}

	const defaultLocale = router.defaultLocale as string;
	const store = configureAppStore(pageProps.initialReduxState);

	return (
		<>
			<SessionProvider session={session} basePath="/next/api/auth">
				<ApolloProvider client={client}>
					<ReduxProvider store={store}>
						<QueryParamProvider
							adapter={NextAdapterPages}
							options={{
								skipUpdateWhenNoChange: true
							}}
						>
							<IntlProvider
								locale={locale || 'nl'}
								defaultLocale={defaultLocale}
								messages={getMessagesForLocale(locale)}
							>
								{process.env.NEXT_PUBLIC_GTM_ID && (
									<Script
										id="gtm"
										strategy="afterInteractive"
										dangerouslySetInnerHTML={{
											__html: `
												(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
												new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
												j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
												'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=FNP_VxaUvgJLvvE6tUizew&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
												})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
											`
										}}
									/>
								)}

								{process.env.NEXT_PUBLIC_META_PIXEL_ID && (
									<Script
										id="meta-pixel"
										strategy="afterInteractive"
										dangerouslySetInnerHTML={{
											__html: `
												!function(f,b,e,v,n,t,s)
												{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
												n.callMethod.apply(n,arguments):n.queue.push(arguments)};
												if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
												n.queue=[];t=b.createElement(e);t.async=!0;
												t.src=v;s=b.getElementsByTagName(e)[0];
												s.parentNode.insertBefore(t,s)}(window, document,'script',
												'https://connect.facebook.net/en_US/fbevents.js');
												fbq('init', '${process.env.NEXT_PUBLIC_META_PIXEL_ID}');
												fbq('track', 'PageView');
											`
										}}
									/>
								)}

								<Script id="readspeaker_post" type="text/javascript">
									{`<!-- window.rsConf = {general: {usePost: true}, params: '//cdn-eu.readspeaker.com/script/4480/webReader/webReader.js?pids=wr&amp;disable=skipbuttons,speedbutton&amp;notools=1'}; /-->`}
								</Script>

								{router.route.includes('op-oost') || router.route.includes('efro-oost') ? (
									<style jsx global>
										{globalStylesOost}
									</style>
								) : (
									<style jsx global>
										{globalStyles}
									</style>
								)}
								<Component {...pageProps} />

								<ToastContainer stacked />
							</IntlProvider>
						</QueryParamProvider>
					</ReduxProvider>
				</ApolloProvider>
			</SessionProvider>
		</>
	);
};

export default GelderlandClientApp;
