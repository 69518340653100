/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectOption } from 'components/core/select';
import { ITabItem } from 'components/tab-filter/tab-item/tab-item';

export interface ISearchResultItem {
	title: string;
	description: string;
	slug: string;
	date: Date | string;
}

export interface IDocumentResultItem {
	name: string;
	extension: string;
	size: number;
	date: Date | string;
	href: string;
}

export interface ISearchResultState {
	searchResultItems?: ISearchResultItem[] | null;
	documentResultItems?: IDocumentResultItem[] | null;
	internalNotes?: ISearchResultItem[] | null;
	totalSearchResults: number;
	selectedType?: ITabItem;
	search: string;
	sort: SelectOption;
	didSearch?: boolean;
	isLoading?: boolean;
	startDate?: Date;
	endDate?: Date;
}

export const initialState: ISearchResultState = {
	searchResultItems: undefined,
	totalSearchResults: 0,
	documentResultItems: [],
	internalNotes: [],
	selectedType: { id: 'pages', label: '' },
	search: '',
	sort: {
		id: 'createdAt:desc',
		label: 'Sorteren: nieuwste eerst'
	},
	didSearch: false,
	isLoading: true
};

export const slice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchResultItems: (state, action: PayloadAction<ISearchResultItem[]>) => {
			state.searchResultItems = action.payload;
			state.didSearch = true;
			state.isLoading = false;
		},
		setDocumentResultItems: (state, action: PayloadAction<IDocumentResultItem[]>) => {
			state.documentResultItems = action.payload;
			state.didSearch = true;
			state.isLoading = false;
		},
		setInternalNotes: (state, action: PayloadAction<ISearchResultItem[]>) => {
			state.internalNotes = action.payload;
			state.didSearch = true;
			state.isLoading = false;
		},
		setTotalSearchResults: (state, action: PayloadAction<number>) => {
			state.totalSearchResults = action.payload;
		},
		setSort: (state, action: PayloadAction<SelectOption>) => {
			state.sort = action.payload;
		},
		setSelectedType: (state, action: PayloadAction<ITabItem | undefined>) => {
			state.selectedType = action.payload;
		},
		setSearch: (state, action: PayloadAction<string>) => {
			state.search = action.payload;
		},
		setDidSearch: (state, action: PayloadAction<boolean>) => {
			state.didSearch = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setStartDate: (state, action: PayloadAction<Date | undefined>) => {
			state.startDate = action.payload;
		},
		setEndDate: (state, action: PayloadAction<Date | undefined>) => {
			state.endDate = action.payload;
		}
	}
});

export const {
	setSearchResultItems,
	setDocumentResultItems,
	setTotalSearchResults,
	setInternalNotes,
	setSelectedType,
	setSearch,
	setSort,
	setIsLoading,
	setStartDate,
	setEndDate
} = slice.actions;

export default slice.reducer;
