/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PublicationState } from 'api/graphql-types';
import { CONTENT_TYPES } from 'constants/content-types';

export interface ISettingsState {
	hasChanges: boolean;
	publicationState: PublicationState;
	contentType?: CONTENT_TYPES;
	contentTypeId?: string;
	showNeutralStyle: boolean;
	hideSearchbar: boolean;
	hideLanguageSwitcher: boolean;
	hideDropdowns: boolean;
	isSubsite: boolean;
}

export const initialState: ISettingsState = {
	hasChanges: false,
	publicationState: 'LIVE',
	showNeutralStyle: false,
	hideLanguageSwitcher: false,
	hideSearchbar: false,
	hideDropdowns: false,
	isSubsite: false
};

export const slice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setHasChanges: (state, action: PayloadAction<boolean>) => {
			state.hasChanges = action.payload;
		},
		setPublicationState: (state, action: PayloadAction<PublicationState>) => {
			state.publicationState = action.payload;
		},
		setShowNeutralStyle: (state, action: PayloadAction<boolean>) => {
			state.showNeutralStyle = action.payload;
		},
		setHideSearchbar: (state, action: PayloadAction<boolean>) => {
			state.hideSearchbar = action.payload;
		},
		setHideLanguageSwitcher: (state, action: PayloadAction<boolean>) => {
			state.hideLanguageSwitcher = action.payload;
		},
		setIsSubsite: (state, action: PayloadAction<boolean>) => {
			state.isSubsite = action.payload;
		},
		setHideDropdowns: (state, action: PayloadAction<boolean>) => {
			state.hideDropdowns = action.payload;
		},
		resetStore: (state) => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			state = initialState;
		}
	}
});

export const {
	setHasChanges,
	resetStore,
	setShowNeutralStyle,
	setHideLanguageSwitcher,
	setHideSearchbar,
	setIsSubsite,
	setHideDropdowns
} = slice.actions;

export default slice.reducer;
