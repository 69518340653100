/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	EfroOostNewsarticle,
	EfroOostNewsArticleFieldsFragment,
	Newsarticle,
	NewsArticleFieldsFragment,
	OpOostNewsarticle,
	OpOostNewsArticleFieldsFragment,
	RegioExpresNewsarticle,
	RegioExpresNewsArticleFieldsFragment
} from '../../api/graphql-types';

export interface NewsAlgoliaFragment
	extends Omit<Newsarticle | RegioExpresNewsarticle | OpOostNewsarticle | EfroOostNewsarticle, 'modules'> {}

export interface INewsState {
	newsArticles: NewsAlgoliaFragment[];
	featuredNewsArticles:
		| NewsArticleFieldsFragment[]
		| RegioExpresNewsArticleFieldsFragment[]
		| OpOostNewsArticleFieldsFragment[]
		| EfroOostNewsArticleFieldsFragment[];
	newsArticlesCount: number;
	currentPage: number;
}

export const initialState: INewsState = {
	newsArticles: [],
	featuredNewsArticles: [],
	newsArticlesCount: 0,
	currentPage: 1
};

export const slice = createSlice({
	name: 'news',
	initialState,
	reducers: {
		setNewsArticles: (state, action: PayloadAction<any>) => {
			state.newsArticles = action.payload;
		},
		setFeaturedNewsArticles: (state, action: PayloadAction<any>) => {
			state.featuredNewsArticles = action.payload;
		},
		setnewsArticlesCount: (state, action: PayloadAction<number>) => {
			state.newsArticlesCount = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		resetStore: (state) => {
			state.newsArticles = [];
			state.currentPage = 1;
			state.newsArticlesCount = 0;
		}
	}
});

export const { setNewsArticles, setFeaturedNewsArticles, resetStore, setCurrentPage, setnewsArticlesCount } =
	slice.actions;

export default slice.reducer;
