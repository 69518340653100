/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatVacancyOverviewItem } from 'utils/fetch-format-vacancies';

export type Vacancy = ReturnType<typeof formatVacancyOverviewItem>;

export interface IVacanciesState {
	vacancies: Vacancy[];
	totalVacancies: number;
	currentPage: number;
}

export const initialState: IVacanciesState = {
	vacancies: [],
	totalVacancies: 0,
	currentPage: 1
};

export const slice = createSlice({
	name: 'vacancies',
	initialState,
	reducers: {
		setVacancies: (state, action: PayloadAction<any>) => {
			state.vacancies = action.payload;
		},
		setTotalVacancies: (state, action: PayloadAction<number>) => {
			state.totalVacancies = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		resetStore: (state) => {
			state.vacancies = [];
			state.currentPage = 1;
			state.totalVacancies = 0;
		}
	}
});

export const { setVacancies, resetStore, setCurrentPage, setTotalVacancies } = slice.actions;

export default slice.reducer;
