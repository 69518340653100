/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PressReleaseFieldsFragment } from 'api/graphql-types';

export interface IPressReleaseState {
	pressReleases: PressReleaseFieldsFragment[];
	totalPressReleases: number;
	currentPage: number;
}

export const initialState: IPressReleaseState = {
	pressReleases: [],
	totalPressReleases: 0,
	currentPage: 1
};

export const slice = createSlice({
	name: 'pressReleases',
	initialState,
	reducers: {
		setPressReleases: (state, action: PayloadAction<any>) => {
			state.pressReleases = action.payload;
		},
		setTotalPressReleases: (state, action: PayloadAction<number>) => {
			state.totalPressReleases = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		resetStore: (state) => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			state = initialState;
		}
	}
});

export const { setPressReleases, resetStore, setCurrentPage, setTotalPressReleases } = slice.actions;

export default slice.reducer;
