/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoadMaintenanceFieldsFragment } from '../../api/graphql-types';

export interface IRoadMaintenanceState {
	roadMaintenances?: RoadMaintenanceFieldsFragment[] | null;
	roadMaintenancesCount: number;
}

export const initialState: IRoadMaintenanceState = {
	roadMaintenances: undefined,
	roadMaintenancesCount: 0
};

export const slice = createSlice({
	name: 'roadMaintenance',
	initialState,
	reducers: {
		setRoadMaintenanceOverviewItems: (state, action: PayloadAction<any>) => {
			state.roadMaintenances = action.payload;
		},
		setTotalRoadMaintenance: (state, action: PayloadAction<number>) => {
			state.roadMaintenancesCount = action.payload;
		}
	}
});

export const { setRoadMaintenanceOverviewItems, setTotalRoadMaintenance } = slice.actions;

export default slice.reducer;
