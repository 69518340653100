/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import xorBy from 'lodash/xorBy';
import { CheckboxItem } from 'components/core/checkbox-group';
import { SelectOption } from 'components/core/select';
import { PermitFieldsFragment } from '../../api/graphql-types';

export interface PermitAlgoliaFragment extends Omit<PermitFieldsFragment, 'modules'> {}

export interface IPermitLaw {
	name: string;
	locale: string;
	id: string;
}

export interface IproductType {
	name: string;
	locale: string;
	id: string;
}
export interface IPermitsState {
	permits: PermitFieldsFragment[];
	permitsCount: number;
	permitLaws: IPermitLaw[];
	permitLawCount: number;
	permitProductTypes: IproductType[];
	permitProductTypeCount: number;
	currentPage: number;
	isLoading: boolean;
	sort: SelectOption;
	searchValue: string;
	filters: {
		selectedThemes: CheckboxItem[];
		selectedLaws: CheckboxItem[];
		selectedProductTypes: CheckboxItem[];
	};
	modal: {
		modalTitle: string;
		modalType: string;
		modalOpen: boolean;
	};
}

export const initialState: IPermitsState = {
	permits: [],
	permitsCount: 0,
	permitLaws: [],
	permitLawCount: 0,
	permitProductTypes: [],
	permitProductTypeCount: 0,
	currentPage: 1,
	isLoading: false,
	sort: {
		id: 'title:asc',
		label: 'A tot Z'
	},
	searchValue: '',
	filters: {
		selectedThemes: [],
		selectedLaws: [],
		selectedProductTypes: []
	},
	modal: {
		modalTitle: '',
		modalType: '',
		modalOpen: false
	}
};

export const slice = createSlice({
	name: 'permits',
	initialState,
	reducers: {
		setPermits: (state, action: PayloadAction<any>) => {
			state.permits = action.payload;
		},
		setPermitsCount: (state, action: PayloadAction<number>) => {
			state.permitsCount = action.payload;
		},
		setPermitLaws: (state, action: PayloadAction<any>) => {
			state.permitLaws = action.payload;
		},
		setPermitLawCount: (state, action: PayloadAction<number>) => {
			state.permitLawCount = action.payload;
		},
		setPermitProductTypes: (state, action: PayloadAction<any>) => {
			state.permitProductTypes = action.payload;
		},
		setPermitProductTypeCount: (state, action: PayloadAction<number>) => {
			state.permitProductTypeCount = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setSort: (state, action: PayloadAction<SelectOption>) => {
			state.sort = action.payload;
		},
		setSearchValue: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload;
		},
		setSelectedThemes: (state, action: PayloadAction<CheckboxItem[]>) => {
			const newThemeIds = xorBy(state.filters.selectedThemes, action.payload, (theme: any) => theme.id);
			state.filters.selectedThemes = newThemeIds;
		},
		setSelectedLaws: (state, action: PayloadAction<any>) => {
			const newLawsIds = xorBy(state.filters.selectedLaws, action.payload, (law: any) => law.id);
			state.filters.selectedLaws = newLawsIds;
		},
		setSelectedProductTypes: (state, action: PayloadAction<any>) => {
			const newProductTypesIds = xorBy(state.filters.selectedProductTypes, action.payload, (type: any) => type.id);
			state.filters.selectedProductTypes = newProductTypesIds;
		},
		setModalThemes: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.filters.selectedThemes = action.payload;
		},
		setModalLaws: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.filters.selectedLaws = action.payload;
		},
		setModalProductTypes: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.filters.selectedProductTypes = action.payload;
		},
		resetFilter: (state, action: PayloadAction<any>) => {
			switch (action.payload.key) {
				case 'themes':
					state.filters.selectedThemes = state.filters.selectedThemes.filter((theme) => theme.id !== action.payload.id);
					break;

				case 'laws':
					state.filters.selectedLaws = state.filters.selectedLaws.filter((law) => law.id !== action.payload.id);
					break;

				case 'productTypes':
					state.filters.selectedProductTypes = state.filters.selectedProductTypes.filter(
						(type) => type.id !== action.payload.id
					);
					break;

				default:
					break;
			}
		},
		setModalTitle: (state, action: PayloadAction<string>) => {
			state.modal.modalTitle = action.payload;
		},
		setModalType: (state, action: PayloadAction<string>) => {
			state.modal.modalType = action.payload;
		},
		setModalOpen: (state, action: PayloadAction<boolean>) => {
			state.modal.modalOpen = action.payload;
		},
		resetFilters: (state) => {
			state.filters = {
				selectedThemes: [],
				selectedLaws: [],
				selectedProductTypes: []
			};
		},
		resetStore: (state) => {
			state.permits = [];
			state.currentPage = 1;
			state.permitsCount = 0;
			state.isLoading = false;
			state.sort = {
				id: 'title:ASC',
				label: 'A tot Z'
			};
			state.searchValue = '';
			state.filters = {
				selectedThemes: [],
				selectedLaws: [],
				selectedProductTypes: []
			};
			state.modal = {
				modalTitle: '',
				modalType: '',
				modalOpen: false
			};
		}
	}
});

export const {
	setPermits,
	setPermitsCount,
	setPermitLaws,
	setPermitLawCount,
	setPermitProductTypes,
	setPermitProductTypeCount,
	resetStore,
	setCurrentPage,
	setIsLoading,
	setSort,
	setSearchValue,
	setSelectedThemes,
	setSelectedLaws,
	setSelectedProductTypes,
	resetFilter,
	resetFilters,
	setModalThemes,
	setModalLaws,
	setModalProductTypes,
	setModalTitle,
	setModalType,
	setModalOpen
} = slice.actions;

export default slice.reducer;
