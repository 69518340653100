/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IKind {
	name: string;
	locale: string;
	id: string;
}

export interface IKindsState {
	kinds: IKind[];
	totalKinds?: number;
}

export const initialState: IKindsState = {
	kinds: [],
	totalKinds: undefined
};

export const slice = createSlice({
	name: 'kinds',
	initialState,
	reducers: {
		setKinds: (state, action: PayloadAction<IKind[]>) => {
			state.kinds = action.payload;
		},
		setTotalKinds: (state, action: PayloadAction<number>) => {
			state.totalKinds = action.payload;
		}
	}
});

export const { setKinds, setTotalKinds } = slice.actions;

export default slice.reducer;
