/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThemeEntity } from '../../api/graphql-types';

export interface IThemesState {
	themes: ThemeEntity[];
	totalThemes?: number;
}

export const initialState: IThemesState = {
	themes: [],
	totalThemes: undefined
};

export const slice = createSlice({
	name: 'themes',
	initialState,
	reducers: {
		setThemes: (state, action: PayloadAction<any>) => {
			state.themes = action.payload;
		},
		setTotalThemes: (state, action: PayloadAction<number>) => {
			state.totalThemes = action.payload;
		}
	}
});

export const { setThemes, setTotalThemes } = slice.actions;

export default slice.reducer;
