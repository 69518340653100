import thunk from 'redux-thunk';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { configureStore, ThunkAction, Action, combineReducers, AnyAction } from '@reduxjs/toolkit';
import settingsReducer from 'redux/stores/settings';
import newsReducer from 'redux/stores/news';
import eventsReducer from 'redux/stores/events';
import pressReleaseReducer from 'redux/stores/press-releases';
import roadMaintenanceReducer from 'redux/stores/road-maintenance';
import subsidyReducer from 'redux/stores/subsidy';
import themesReducer from 'redux/stores/themes';
import kindsReducer from 'redux/stores/kinds';
import scalesReducer from 'redux/stores/scales';
import groupsReducer from 'redux/stores/groups';
import permitsReducer from 'redux/stores/permits';
import wobRequestsReducer from 'redux/stores/wob-requests';
import wooRequestsReducer from 'redux/stores/woo-requests';
import mapsFiguresReducer from 'redux/stores/maps-figures';
import tagsReducer from 'redux/stores/tags';
import projectsReducer from 'redux/stores/projects';
import searchReducer from 'redux/stores/search';
import inConvoWithReducer from 'redux/stores/in-convo-with';
import vacanciesReducer from 'redux/stores/vacancies';

const allReducers = combineReducers({
	settings: settingsReducer,
	news: newsReducer,
	inConvoWith: inConvoWithReducer,
	events: eventsReducer,
	pressRelease: pressReleaseReducer,
	roadMaintenance: roadMaintenanceReducer,
	subsidy: subsidyReducer,
	themes: themesReducer,
	kinds: kindsReducer,
	scales: scalesReducer,
	groups: groupsReducer,
	permits: permitsReducer,
	wobRequests: wobRequestsReducer,
	wooRequests: wooRequestsReducer,
	mapsFigures: mapsFiguresReducer,
	tags: tagsReducer,
	projects: projectsReducer,
	search: searchReducer,
	vacancies: vacanciesReducer
});

const rootReducer = (state: ReturnType<typeof allReducers> | undefined, action: AnyAction): RootState => {
	if (action.type === 'settings/resetStore') {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk],
	devTools: {
		name: 'Gelderland'
	}
});

export default function configureAppStore(preloadedState: RootState): typeof store {
	const _store = configureStore({
		reducer: rootReducer,
		middleware: [thunk],
		preloadedState,
		devTools: {
			name: 'Gelderland'
		}
	});

	return _store;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof allReducers>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
