/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISortOption } from 'components/sort';
import { WobRequestFieldsFragment } from '../../api/graphql-types';

export interface IWobRequestsState {
	isLoading: boolean;
	wobRequestItems?: WobRequestFieldsFragment[];
	totalWobRequestItems: number;
	currentPage: number;
	sort: ISortOption;
}

export const initialState: IWobRequestsState = {
	isLoading: false,
	wobRequestItems: undefined,
	totalWobRequestItems: 0,
	currentPage: 1,
	sort: { id: 'createdAt:desc', label: 'Sorteren: nieuw-oud', shortLabel: 'nieuw-oud' }
};

export const slice = createSlice({
	name: 'wobRequests',
	initialState,
	reducers: {
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setWobRequestItems: (state, action: PayloadAction<any>) => {
			state.wobRequestItems = action.payload;
		},
		setTotalWobRequestItems: (state, action: PayloadAction<number>) => {
			state.totalWobRequestItems = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		setSort: (state, action: PayloadAction<ISortOption>) => {
			state.sort = action.payload;
			state.currentPage = 1;
		},
		resetStore: (state) => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			state = initialState;
		}
	}
});

export const { setWobRequestItems, setTotalWobRequestItems, setCurrentPage, setIsLoading, setSort, resetStore } =
	slice.actions;

export default slice.reducer;
