/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGroup {
	name: string;
	locale: string;
	id: string;
}
export interface IGroupsState {
	groups: IGroup[];
	totalGroups?: number;
}

export const initialState: IGroupsState = {
	groups: [],
	totalGroups: undefined
};

export const slice = createSlice({
	name: 'groups',
	initialState,
	reducers: {
		setGroups: (state, action: PayloadAction<IGroup[]>) => {
			state.groups = action.payload;
		},
		setTotalGroups: (state, action: PayloadAction<number>) => {
			state.totalGroups = action.payload;
		}
	}
});

export const { setGroups, setTotalGroups } = slice.actions;

export default slice.reducer;
