/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpOostInConvoWithArticleFieldsFragment } from '../../api/graphql-types';

export interface IInConvoWithState {
	inConvoWithArticles: OpOostInConvoWithArticleFieldsFragment[];
	featuredInConvoWithArticles: OpOostInConvoWithArticleFieldsFragment[];
	totalInConvoWithArticles: number;
	currentPage: number;
}

export const initialState: IInConvoWithState = {
	inConvoWithArticles: [],
	featuredInConvoWithArticles: [],
	totalInConvoWithArticles: 0,
	currentPage: 1
};

export const slice = createSlice({
	name: 'inConvoWith',
	initialState,
	reducers: {
		setInConvoWithArticles: (state, action: PayloadAction<any>) => {
			state.inConvoWithArticles = action.payload;
		},
		setFeaturedInConvoWithArticles: (state, action: PayloadAction<any>) => {
			state.featuredInConvoWithArticles = action.payload;
		},
		setTotalInConvoWithArticles: (state, action: PayloadAction<number>) => {
			state.totalInConvoWithArticles = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		resetStore: (state) => {
			state.inConvoWithArticles = [];
			state.currentPage = 1;
			state.totalInConvoWithArticles = 0;
		}
	}
});

export const {
	setInConvoWithArticles,
	setFeaturedInConvoWithArticles,
	resetStore,
	setCurrentPage,
	setTotalInConvoWithArticles
} = slice.actions;

export default slice.reducer;
