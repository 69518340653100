/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import xorBy from 'lodash/xorBy';
import { CheckboxItem } from 'components/core/checkbox-group';
import { RadioButtonItem } from 'components/core/radio-button-group';
import { SelectOption } from 'components/core/select';
import { MapsFiguresFieldsFragment } from '../../api/graphql-types';

export interface MapsFiguresAlgoliaFragment extends Omit<MapsFiguresFieldsFragment, 'modules'> {}

export interface IMapsFiguresState {
	mapsFigures?: MapsFiguresFieldsFragment[];
	mapsFiguresCount: number;
	currentPage: number;
	isLoading: boolean;
	sort: SelectOption;
	searchValue: string;
	filters: {
		selectedThemes: CheckboxItem[];
		selectedKind?: RadioButtonItem;
		selectedTags: CheckboxItem[];
	};
	modal: {
		modalTitle: string;
		modalType: string;
		modalOpen: boolean;
	};
}

export const initialState: IMapsFiguresState = {
	mapsFigures: [],
	mapsFiguresCount: 0,
	currentPage: 1,
	isLoading: false,
	sort: {
		id: 'name:ASC',
		label: 'A tot Z'
	},
	searchValue: '',
	filters: {
		selectedThemes: [],
		selectedTags: []
	},
	modal: {
		modalTitle: '',
		modalType: '',
		modalOpen: false
	}
};

export const slice = createSlice({
	name: 'mapsFigures',
	initialState,
	reducers: {
		setMapsFigures: (state, action: PayloadAction<any>) => {
			state.mapsFigures = [...action.payload];
		},
		setMapsFiguresCount: (state, action: PayloadAction<number>) => {
			state.mapsFiguresCount = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setSort: (state, action: PayloadAction<SelectOption>) => {
			state.sort = action.payload;
		},
		setSearchValue: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload;
		},
		setSelectedThemes: (state, action: PayloadAction<CheckboxItem[]>) => {
			const newThemeIds = xorBy(state.filters.selectedThemes, action.payload, (theme: any) => theme.id);
			state.filters.selectedThemes = newThemeIds;
		},
		setSelectedKind: (state, action: PayloadAction<RadioButtonItem | undefined>) => {
			state.filters.selectedKind = action.payload;
		},
		setSelectedTags: (state, action: PayloadAction<CheckboxItem[]>) => {
			const newTagIds = xorBy(state.filters.selectedTags, action.payload, (tag: any) => tag.id);
			state.filters.selectedTags = newTagIds;
		},
		setModalThemes: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.filters.selectedThemes = action.payload;
		},
		setModalTags: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.filters.selectedTags = action.payload;
		},
		resetFilter: (state, action: PayloadAction<any>) => {
			switch (action.payload.key) {
				case 'themes':
					state.filters.selectedThemes = state.filters.selectedThemes.filter((theme) => theme.id !== action.payload.id);
					break;
				case 'tags':
					state.filters.selectedTags = state.filters.selectedTags.filter((tag) => tag.id !== action.payload.id);
					break;

				case 'kind':
					state.filters.selectedKind = initialState.filters.selectedKind;
					break;

				default:
					break;
			}
		},
		setModalTitle: (state, action: PayloadAction<string>) => {
			state.modal.modalTitle = action.payload;
		},
		setModalType: (state, action: PayloadAction<string>) => {
			state.modal.modalType = action.payload;
		},
		setModalOpen: (state, action: PayloadAction<boolean>) => {
			state.modal.modalOpen = action.payload;
		},
		resetFilters: (state) => {
			state.filters = {
				selectedThemes: [],
				selectedTags: []
			};
		},
		resetStore: (state) => {
			state.mapsFigures = [];
			state.currentPage = 1;
			state.mapsFiguresCount = 0;
			state.isLoading = false;
			state.sort = {
				id: 'name:ASC',
				label: 'A tot Z'
			};
			state.searchValue = '';
			state.filters = {
				selectedThemes: [],
				selectedTags: []
			};
			state.modal = {
				modalTitle: '',
				modalType: '',
				modalOpen: false
			};
		}
	}
});

export const {
	setMapsFigures,
	setMapsFiguresCount,
	resetStore,
	setCurrentPage,
	setIsLoading,
	setSort,
	setSearchValue,
	setSelectedThemes,
	setSelectedTags,
	setSelectedKind,
	resetFilter,
	resetFilters,
	setModalThemes,
	setModalTags,
	setModalTitle,
	setModalType,
	setModalOpen
} = slice.actions;

export default slice.reducer;
