/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagFieldsFragment } from '../../api/graphql-types';

export interface INewsState {
	tags: TagFieldsFragment[];
	totalTags?: number;
}

export const initialState: INewsState = {
	tags: [],
	totalTags: undefined
};

export const slice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		setTags: (state, action: PayloadAction<any>) => {
			state.tags = action.payload;
		},
		setTotalTags: (state, action: PayloadAction<number>) => {
			state.totalTags = action.payload;
		}
	}
});

export const { setTags, setTotalTags } = slice.actions;

export default slice.reducer;
