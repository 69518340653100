/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import xorBy from 'lodash/xorBy';
import { CheckboxItem } from 'components/core/checkbox-group';
import { SelectOption } from 'components/core/select';
import { WooCategoryCount, WooRequestFieldsFragment } from '../../api/graphql-types';

export interface WOORequestAlgoliaFragment extends Omit<WooRequestFieldsFragment, 'category'> {
	category?: WooRequestFieldsFragment['category'] & {
		title?: string;
	};
}

interface DateRangeFilter {
	startDate: Date | null;
	endDate: Date | null;
}

export interface IWooRequestsState {
	wooRequests: WOORequestAlgoliaFragment[];
	wooRequestsCount: number;
	wooRequestsCountPerCategory: WooCategoryCount[];
	themes: CheckboxItem[];
	themesCount: number;
	categories: CheckboxItem[];
	categoriesCount: number;
	currentPage: number;
	isLoading: boolean;
	sort: SelectOption;
	searchValue: string;
	filters: {
		selectedThemes: CheckboxItem[];
		selectedCategories: CheckboxItem[];
		selectedDateRange: DateRangeFilter | null;
	};
	modal: {
		modalTitle: string;
		modalType: string;
		modalOpen: boolean;
	};
}

export const initialState: IWooRequestsState = {
	wooRequests: [],
	wooRequestsCount: 0,
	wooRequestsCountPerCategory: [],
	themes: [],
	themesCount: 0,
	categories: [],
	categoriesCount: 0,
	currentPage: 1,
	isLoading: false,
	sort: {
		id: 'startDate:desc',
		label: 'A tot Z'
	},
	searchValue: '',
	filters: {
		selectedThemes: [],
		selectedCategories: [],
		selectedDateRange: null
	},
	modal: {
		modalTitle: '',
		modalType: '',
		modalOpen: false
	}
};

export const slice = createSlice({
	name: 'wooRequests',
	initialState,
	reducers: {
		setWooRequests: (state, action: PayloadAction<WOORequestAlgoliaFragment[]>) => {
			state.wooRequests = action.payload;
		},
		setWooRequestsCount: (state, action: PayloadAction<number>) => {
			state.wooRequestsCount = action.payload;
		},
		setWooRequestsCountPerCategory: (state, action: PayloadAction<WooCategoryCount[]>) => {
			state.wooRequestsCountPerCategory = action.payload;
		},
		setThemes: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.themes = action.payload;
		},
		setThemesCount: (state, action: PayloadAction<number>) => {
			state.themesCount = action.payload;
		},
		setCategories: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.categories = action.payload;
		},
		setCategoriesCount: (state, action: PayloadAction<number>) => {
			state.categoriesCount = action.payload;
		},
		setCurrentPage: (state, action: PayloadAction<number>) => {
			state.currentPage = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setSort: (state, action: PayloadAction<SelectOption>) => {
			state.sort = action.payload;
		},
		setSearchValue: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload;
		},
		setSelectedThemes: (state, action: PayloadAction<CheckboxItem[]>) => {
			const newThemeIds = xorBy(state.filters.selectedThemes, action.payload, (theme: any) => theme.id);
			state.filters.selectedThemes = newThemeIds;
		},
		setSelectedCategories: (state, action: PayloadAction<CheckboxItem[]>) => {
			const newCategoryIds = xorBy(state.filters.selectedCategories, action.payload, (category: any) => category.id);
			state.filters.selectedCategories = newCategoryIds;
		},
		setSelectedDateRange: (state, action: PayloadAction<DateRangeFilter | null>) => {
			state.filters.selectedDateRange = action.payload;
		},
		setModalThemes: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.filters.selectedThemes = action.payload;
		},
		setModalCategories: (state, action: PayloadAction<CheckboxItem[]>) => {
			state.filters.selectedCategories = action.payload;
		},
		resetFilter: (state, action: PayloadAction<any>) => {
			switch (action.payload.key) {
				case 'themes':
					state.filters.selectedThemes = state.filters.selectedThemes.filter((theme) => theme.id !== action.payload.id);
					break;
				case 'categories':
					state.filters.selectedCategories = state.filters.selectedCategories.filter(
						(category) => category.id !== action.payload.id
					);
					break;
				case 'dateRange':
					state.filters.selectedDateRange = initialState.filters.selectedDateRange;
					break;
				default:
					break;
			}
		},
		setModalTitle: (state, action: PayloadAction<string>) => {
			state.modal.modalTitle = action.payload;
		},
		setModalType: (state, action: PayloadAction<string>) => {
			state.modal.modalType = action.payload;
		},
		setModalOpen: (state, action: PayloadAction<boolean>) => {
			state.modal.modalOpen = action.payload;
		},
		resetFilters: (state) => {
			state.filters = {
				selectedThemes: [],
				selectedCategories: [],
				selectedDateRange: null
			};
		},
		resetStore: (state) => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			state = initialState;
		}
	}
});

export const {
	setWooRequests,
	setWooRequestsCount,
	setWooRequestsCountPerCategory,
	setThemes,
	setThemesCount,
	setCategories,
	setCategoriesCount,
	resetStore,
	setCurrentPage,
	setIsLoading,
	setSort,
	setSearchValue,
	setSelectedThemes,
	setSelectedCategories,
	resetFilter,
	resetFilters,
	setModalThemes,
	setModalCategories,
	setModalTitle,
	setModalType,
	setModalOpen,
	setSelectedDateRange
} = slice.actions;

export default slice.reducer;
